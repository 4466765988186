<template>
  <g>
    <defs>
      <linearGradient id="ebo5k6ok0a" x1="75.083%" x2="18.312%" y1="100%" y2="-38.745%">
        <stop offset="0%" stop-color="#D20051" />
        <stop offset="100%" stop-color="#FFA300" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <path
            stroke="#D20051"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="5"
            d="M6.52 70.536h36.96c3.315 0 5.952-2.727 5.595-5.87-2.301-20.246-16.04-19.912-16.04-28.952 0-9.04 13.914-8.538 16.039-28.952.335-3.144-2.279-5.87-5.594-5.87H6.52C3.205.893.598 3.619.926 6.763c2.125 20.414 16.038 19.745 16.038 28.952 0 9.208-13.737 8.706-16.038 28.952-.358 3.143 2.279 5.87 5.594 5.87z"
            transform="translate(-160 -203) translate(162.5 205)"
          />
          <path
            fill="url(#ebo5k6ok0a)"
            d="M39.615 65.179H10.457c-2.611 0-3.348-3.014-1.516-4.882 4.433-4.493 13.38-7.71 13.38-12.864V30.357c0-3.323-6.36-5.86-10.297-11.25-.65-.889-.584-2.143 1.067-2.143h23.894c1.408 0 1.713 1.244 1.072 2.135-3.88 5.399-10.378 7.918-10.378 11.258v17.076c0 5.11 9.326 7.868 13.46 12.869 1.666 2.016 1.083 4.877-1.524 4.877z"
            transform="translate(-160 -203) translate(162.5 205)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
